import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  position: google.maps.LatLng;
  strings: Record<string, string>;
}

export default function DirectionsPopup(props: Props) {
  const { children, position, strings } = props;

  const dirUrl = `https://www.google.com/maps/dir/?api=1&destination=${position.lat()}%2C${position.lng()}`;

  return (
    <div className="directions-popup">
      <div className="directions">
        <a href={dirUrl}>{strings.directions}</a>
      </div>
      <div className="popup-content">{children}</div>
    </div>
  );
}
