import { useMemo, ReactNode } from "react";
import { renderToString } from "react-dom/server";

type AnyMarker = google.maps.Marker | google.maps.marker.AdvancedMarkerElement;

export type OpenInfoWindow = (content: ReactNode, anchor: AnyMarker) => void;

export default function useInfoWindow(map: google.maps.Map): OpenInfoWindow {
  const infoWindow = useMemo(() => {
    return new google.maps.InfoWindow({ minWidth: 330 });
  }, []);

  const open = (content: ReactNode, anchor: AnyMarker) => {
    infoWindow.setContent(renderToString(content));
    infoWindow.open({ anchor: anchor, map: map });
  };

  return open;
}
