import React, { useEffect, useState } from "react";
import Marker from "./Marker";

interface Props {
  map: google.maps.Map;
  assets: MapAssets;
}

export default function UserPosition(props: Props) {
  const { assets, map } = props;

  const [position, setPosition] = useState<google.maps.LatLngLiteral>(null);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition((p) => {
      setPosition({ lat: p.coords.latitude, lng: p.coords.longitude });
    });
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const icon = {
    url: assets.location,
    size: new google.maps.Size(20, 20),
    scaledSize: new google.maps.Size(20, 20)
  };

  return (
    <React.Fragment>
      {position && (
        <Marker
          map={map}
          position={position}
          icon={icon}
          title="Your location"
        />
      )}
    </React.Fragment>
  );
}
