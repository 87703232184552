import { create } from "zustand";

interface LightboxState {
  image: Image | null;
}

const useLightboxStore = create<LightboxState>(() => ({
  image: null
}));

export function closeLightbox() {
  useLightboxStore.setState(() => ({ image: null }));
}

export function openLightbox(image: Image) {
  console.log(image);
  useLightboxStore.setState(() => ({ image: image }));
}

export default useLightboxStore;
