import React, { useEffect } from "react";
import Artist from "./ArtistList/Artist";
import { fetchLists } from "../stores/useListsStore";

interface Props {
  artists: Artist[];
  strings: Localizations;
}

export default function ArtistList(props: Props) {
  const { artists, strings } = props;

  useEffect(() => {
    void fetchLists();
  }, []);

  return (
    <div className="artists-list">
      {artists.map((a) => (
        <Artist key={a.id} artist={a} strings={strings} />
      ))}
    </div>
  );
}
