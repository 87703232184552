import React from "react";
import DirectionsPopup from "./DirectionsPopup";

interface Props {
  sponsors: SponsorLocation[];
  position: google.maps.LatLng;
  strings: Record<string, string>;
}

export default function SponsorsPopup(props: Props) {
  const { sponsors, position, strings } = props;

  return (
    <DirectionsPopup position={position} strings={strings}>
      <ul>
        {sponsors.map((location) => (
          <li key={location.id}>
            <a href={location.sponsor.url} className="list-item sponsor">
              <div className="image">
                {location.sponsor.image_url && (
                  <img src={location.sponsor.image_url} />
                )}
              </div>
              <div className="text">
                <div className="name">{location.name}</div>
                <div className="address">{location.address}</div>
                <div>{location.sponsor.url.replace(/^https?:\/\//, "")}</div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </DirectionsPopup>
  );
}
