import React from "react";
import Marker from "./Marker";
import SponsorsPopup from "./SponsorsPopup";
import { OpenInfoWindow } from "./useInfoWindow";

interface Props {
  assets: MapAssets;
  map: google.maps.Map;
  sponsors: SponsorLocation[];
  openInfoWindow: OpenInfoWindow;
  strings: Record<string, string>;
  zoom: ZoomState;
  zoomTo: ZoomToFn;
}

function sponsorPosition(sponsor: SponsorLocation) {
  return {
    lat: parseFloat(`${sponsor.latitude}`),
    lng: parseFloat(`${sponsor.longitude}`)
  };
}

export default function ArtistMarkers(props: Props) {
  const { assets, sponsors, map, openInfoWindow, strings, zoom, zoomTo } =
    props;

  const icon = {
    url: assets.sponsor,
    size: new google.maps.Size(25, 25),
    scaledSize: new google.maps.Size(25, 25)
  };

  const showSponsor =
    (sponsor: SponsorLocation) => (marker: google.maps.Marker) => {
      openInfoWindow(
        <SponsorsPopup
          sponsors={[sponsor]}
          position={marker.getPosition()}
          strings={strings}
        />,
        marker
      );
    };

  const handleRender = (marker: google.maps.Marker) => {
    const sponsor = marker.get("sponsor") as SponsorLocation;
    if (zoom && zoom[0] == "sponsor" && zoom[1] == sponsor.id) {
      zoomTo(marker, showSponsor(sponsor));
    }
  };

  return (
    <React.Fragment>
      {sponsors.map((s) => (
        <Marker
          key={s.id}
          data={{ sponsor: s }}
          map={map}
          icon={icon}
          title={s.name}
          onClick={showSponsor(s)}
          onRender={handleRender}
          position={sponsorPosition(s)}
        />
      ))}
    </React.Fragment>
  );
}
