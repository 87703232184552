import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import ArtistMarkers from "./Map/ArtistMarkers";
import SponsorMarkers from "./Map/SponsorMarkers";
import UserPosition from "./Map/UserPosition";
import useInfoWindow from "./Map/useInfoWindow";
import { Cluster } from "@googlemaps/markerclusterer";

type Clusterer = { clusters: Cluster[] };

interface Props {
  artists: Artist[];
  strings: Record<string, string>;
  assets: MapAssets;
  sponsors: SponsorLocation[];
}

function latLng(artist: Artist) {
  return {
    lat: parseFloat(`${artist.latitude}`),
    lng: parseFloat(`${artist.longitude}`)
  };
}

function haversine(
  pos1: google.maps.LatLngLiteral,
  pos2: google.maps.LatLngLiteral
) {
  const rlat1 = pos1.lat * (Math.PI / 180);
  const rlat2 = pos2.lat * (Math.PI / 180);
  const difflat = rlat2 - rlat1;
  const difflon = (pos2.lng - pos1.lng) * (Math.PI / 180);

  return (
    2 *
    6371.071 * // Radius of the Earth in km
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    )
  );
}

function positionArtists(artists: Artist[]) {
  const positions: google.maps.LatLngLiteral[] = [];

  return artists.map((a) => {
    let position = latLng(a);

    const close = positions.filter((p) => haversine(p, position) <= 0.01);
    if (close.length > 0) {
      position = close[0];
    } else {
      positions.push(position);
    }

    return { ...a, position: position };
  });
}

function visibleMarker(marker: google.maps.Marker) {
  const clusterer = marker.get("clusterer") as Clusterer;
  if (clusterer) {
    const currentCluster = clusterer.clusters.filter(
      (c) => c.markers.indexOf(marker) != -1
    );
    if (currentCluster.length > 0) {
      return currentCluster[0].marker as google.maps.Marker;
    }
  }
  return marker;
}

export default function ArtistsMap(props: Props) {
  const { assets, sponsors, strings } = props;

  const artists = useMemo(
    () => positionArtists(props.artists),
    [props.artists]
  );

  const containerRef = useRef<HTMLDivElement>();
  const [map, setMap] = useState<google.maps.Map>(null);
  const [zoom, setZoom] = useState<ZoomState>(null);
  const [showSaturday, setShowSaturday] = useState(true);
  const [showSunday, setShowSunday] = useState(true);
  const openInfoWindow = useInfoWindow(map);

  useEffect(() => {
    const map = new google.maps.Map(containerRef.current, {
      zoom: 11,
      scrollwheel: true,
      center: new window.google.maps.LatLng(59.91, 10.75),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      maxZoom: 18
    });
    setMap(map);
    return () => {
      setMap(null);
    };
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      if (document.location.hash.match(/sponsor-location-([\d]+)/)) {
        setZoom([
          "sponsor",
          parseInt(
            document.location.hash.match(/sponsor-location-([\d]+)/)[1],
            10
          )
        ]);
      } else if (document.location.hash.match(/artist-([\d]+)/)) {
        setZoom([
          "artist",
          parseInt(document.location.hash.match(/artist-([\d]+)/)[1], 10)
        ]);
      }
    }
  }, []);

  const zoomTo = (marker: google.maps.Marker, callback?: ZoomCallback) => {
    if (map) {
      map.setCenter(marker.getPosition());
      map.setZoom(15);

      setTimeout(() => {
        if (callback) {
          callback(visibleMarker(marker));
        }
      }, 200);

      setZoom(null);
    }
  };

  const toggleSaturday = (evt: ChangeEvent<HTMLInputElement>) => {
    setShowSaturday(evt.target.checked);
  };

  const toggleSunday = (evt: ChangeEvent<HTMLInputElement>) => {
    setShowSunday(evt.target.checked);
  };

  return (
    <div className="artists-map">
      <div className="legend">
        <label className="saturday">
          <input
            type="checkbox"
            checked={showSaturday}
            onChange={toggleSaturday}
          />
          {strings.open_saturday}
        </label>
        <label className="sunday">
          <input type="checkbox" checked={showSunday} onChange={toggleSunday} />
          {strings.open_sunday}
        </label>
      </div>

      <div className="map" ref={containerRef} />
      {map && (
        <React.Fragment>
          <UserPosition map={map} assets={assets} />
          {showSaturday && (
            <ArtistMarkers
              map={map}
              day="saturday"
              artists={artists.filter((a) => a.day == "saturday")}
              assets={assets}
              openInfoWindow={openInfoWindow}
              strings={strings}
              zoom={zoom}
              zoomTo={zoomTo}
            />
          )}
          {showSunday && (
            <ArtistMarkers
              map={map}
              day="sunday"
              artists={artists.filter((a) => a.day == "sunday")}
              assets={assets}
              openInfoWindow={openInfoWindow}
              strings={strings}
              zoom={zoom}
              zoomTo={zoomTo}
            />
          )}
          <SponsorMarkers
            map={map}
            sponsors={sponsors}
            assets={assets}
            openInfoWindow={openInfoWindow}
            strings={strings}
            zoom={zoom}
            zoomTo={zoomTo}
          />
        </React.Fragment>
      )}
    </div>
  );
}
