import React, { ReactNode } from "react";
import ListsButton from "../ListsButton";

interface Props {
  artist: Artist;
  strings: Localizations;
  children?: ReactNode;
}

export default function Artist(props: Props) {
  const { artist, children, strings } = props;

  return (
    <div className="artist">
      {children}
      <a href={artist.url} className="artist-link">
        <div className="image">
          {artist.image_url && <img src={artist.image_url} />}
        </div>
        <div className="text">
          <h3>{artist.name}</h3>
          <p>{artist.technique}</p>
          <p>{artist.full_address}</p>
        </div>
      </a>
      <ListsButton artist={artist} strings={strings} />
    </div>
  );
}
