import React from "react";
import DirectionsPopup from "./DirectionsPopup";

interface Props {
  artists: Artist[];
  position: google.maps.LatLng;
  strings: Record<string, string>;
}

export default function ArtistsPopup(props: Props) {
  const { artists, position, strings } = props;

  return (
    <DirectionsPopup position={position} strings={strings}>
      <ul>
        {artists.map((artist) => (
          <li key={artist.id}>
            <a href={artist.url} className="list-item artist">
              <div className="image">
                {artist.image_url && <img src={artist.image_url} />}
              </div>
              <div className="text">
                <div className="name">{artist.name}</div>
                <div>{artist.technique}</div>
                <div className="address">{artist.full_address}</div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </DirectionsPopup>
  );
}
