import React from "react";
import useListsStore, { inList } from "../../stores/useListsStore";

interface Props {
  artist: Artist;
  artists: Artist[];
  dispatch: (action: Lists.Action) => void;
  list: List;
  strings: Localizations;
}

export default function MoveButtons(props: Props) {
  const { artist, artists, dispatch, strings } = props;

  const lists = useListsStore((state) => state.lists);
  const list = lists.filter((l) => l.id == props.list.id)[0];

  const handleMoveUp = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "moveUp", payload: artist });
  };

  const handleMoveDown = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "moveDown", payload: artist });
  };

  return (
    <div className="move-buttons">
      {list && inList(list, artist) && (
        <input type="hidden" name="list[artist_ids][]" value={artist.id} />
      )}
      <button
        className="move-up"
        onClick={handleMoveUp}
        disabled={artist == artists[0]}>
        {strings.move_up}
      </button>
      <button
        className="move-down"
        onClick={handleMoveDown}
        disabled={artist == artists[artists.length - 1]}>
        {strings.move_down}
      </button>
    </div>
  );
}
