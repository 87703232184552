import React, { useEffect, useRef } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

interface Props {
  map: google.maps.Map;
  position: google.maps.LatLngLiteral;
  title: string;
  cluster?: MarkerClusterer;
  icon?: google.maps.Icon;
  data?: Record<string, unknown>;
  onClick?: (
    marker: google.maps.Marker,
    evt?: google.maps.MapMouseEvent
  ) => void;
  onRender?: (marker: google.maps.Marker) => void;
}

export default function Marker(props: Props) {
  const { cluster, map, icon, position, title, data, onClick, onRender } =
    props;

  const ref = useRef<google.maps.Marker>(null);

  const setMap = () => {
    if (ref.current) {
      ref.current.setMap(map);
      if (cluster) {
        cluster.addMarker(ref.current);
        ref.current.setValues({ clusterer: cluster });
      }
    }
  };

  const handleClick = (event: google.maps.MapMouseEvent) => {
    onClick(ref.current, event);
  };

  useEffect(() => {
    const marker = new google.maps.Marker({
      position: position,
      title: title,
      icon: icon,
      optimized: false
    });
    if (data) {
      marker.setValues(data);
    }
    if (onClick) {
      marker.addListener("click", handleClick);
    }
    ref.current = marker;
    setMap();
    if (onRender) {
      onRender(ref.current);
    }

    return () => {
      if (cluster) {
        cluster.removeMarker(marker);
      }
      marker.setMap(null);
      ref.current = null;
    };
  }, []);

  useEffect(setMap, [cluster, map]);

  useEffect(() => {
    if (ref.current) {
      ref.current.setPosition(position);
    }
  }, [position]);

  return <React.Fragment />;
}
