import React from "react";

interface Props {
  strings: Localizations;
}

export default function LoginNotice(props: Props) {
  const { strings } = props;

  return (
    <div className="login-notice">
      <h3>{strings.add_artist_to_list}</h3>
      <div dangerouslySetInnerHTML={{ __html: strings.login_notice_html }} />
    </div>
  );
}
