import { useMemo } from "react";
import {
  Cluster,
  MarkerClusterer,
  GridAlgorithm,
  onClusterClickHandler
} from "@googlemaps/markerclusterer";

export default function useCluster(
  map: google.maps.Map,
  clickHandler: onClusterClickHandler,
  color: string
) {
  const algorithm = new GridAlgorithm({ maxZoom: 19 });

  const renderer = {
    render: ({ count, position }: Cluster) => {
      const svg = window.btoa(`
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="16.5" cy="16.5" r="16.5" fill="${color}"/>
        </svg>`);
      return new google.maps.Marker({
        position,
        icon: {
          url: `data:image/svg+xml;base64,${svg}`,
          scaledSize: new google.maps.Size(33, 33)
        },
        label: {
          text: String(count),
          className: "cluster-label",
          color: "#fff",
          fontFamily: '"Neue Haas Unica", sans-serif',
          fontSize: "14px"
        },
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
      });
    }
  };

  const cluster = useMemo(() => {
    return new MarkerClusterer({
      map: map,
      algorithm: algorithm,
      onClusterClick: clickHandler,
      renderer: renderer
    });
  }, [map]);
  return cluster;
}
