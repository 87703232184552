import React from "react";
import { Cluster } from "@googlemaps/markerclusterer";
import Marker from "./Marker";
import useCluster from "./useCluster";
import ArtistsPopup from "./ArtistsPopup";
import { OpenInfoWindow } from "./useInfoWindow";

interface Props {
  assets: MapAssets;
  map: google.maps.Map;
  artists: Artist[];
  day: string;
  openInfoWindow: OpenInfoWindow;
  strings: Record<string, string>;
  zoom: ZoomState;
  zoomTo: ZoomToFn;
}

export default function ArtistMarkers(props: Props) {
  const { assets, artists, day, map, openInfoWindow, strings, zoom, zoomTo } =
    props;

  const handleClusterClick = (
    event: google.maps.MapMouseEvent,
    cluster: Cluster,
    map: google.maps.Map
  ) => {
    if (map.getZoom() == 18) {
      const artists = cluster.markers.map(
        (m: google.maps.Marker) => m.get("artist") as Artist
      );
      openInfoWindow(
        <ArtistsPopup
          artists={artists}
          position={cluster.position}
          strings={strings}
        />,
        cluster.marker
      );
    } else {
      map.fitBounds(cluster.bounds);
    }
  };

  const showArtist = (artist: Artist) => (marker: google.maps.Marker) => {
    openInfoWindow(
      <ArtistsPopup
        artists={[artist]}
        position={marker.getPosition()}
        strings={strings}
      />,
      marker
    );
  };

  const cluster = useCluster(
    map,
    handleClusterClick,
    day == "saturday" ? "#0091FF" : "#FF552E"
  );

  const icon = {
    url: assets[day],
    size: new google.maps.Size(25, 25),
    scaledSize: new google.maps.Size(25, 25)
  };

  const handleRender = (marker: google.maps.Marker) => {
    const artist = marker.get("artist") as Artist;
    if (zoom && zoom[0] == "artist" && zoom[1] == artist.id) {
      zoomTo(marker, showArtist(artist));
    }
  };

  return (
    <React.Fragment>
      {artists.map((a) => (
        <Marker
          key={a.id}
          data={{ artist: a }}
          cluster={cluster}
          map={map}
          onClick={showArtist(a)}
          onRender={handleRender}
          icon={icon}
          title={a.name}
          position={a.position}
        />
      ))}
    </React.Fragment>
  );
}
