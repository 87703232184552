import React, { ReactNode } from "react";
import { closeModal } from "../stores/useModalStore";

interface Props {
  children: ReactNode;
}

export default function Modal(props: Props) {
  const { children } = props;

  const handleClose = (evt: React.MouseEvent) => {
    evt.preventDefault();
    closeModal();
  };

  return (
    <div className="modal">
      <button className="close-modal" onClick={handleClose}>
        Close
      </button>
      {children}
    </div>
  );
}
